var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{class:'login-' +
        _vm.className +
        '-on login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-white',attrs:{"id":"kt_login"}},[_c('div',{staticClass:"login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden"},[_c('div',{staticClass:"d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35"},[_c('div',{staticClass:"d-flex flex-column-fluid flex-column flex-center"},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])]),_c('div',{staticClass:"content order-1 order-lg-2 d-flex flex-column w-100 pb-0",staticStyle:{"background-color":"#B1DCED"}},[_c('div',{staticClass:"d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7"},[_c('h3',[_c('router-link',{attrs:{"to":{ name: 'home' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
        var isActive = ref.isActive;
        var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"display4 font-weight-bolder my-7 text-dark",staticStyle:{"color":"#986923"},attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.brand)+" ")])]}}])})],1),_c('p',{staticClass:"font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70"},[_vm._v(" "+_vm._s(_vm.$t("The digital micro-services buying and selling platform"))+" ")])]),_c('div',{staticClass:"content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",style:({ backgroundImage: ("url(" + _vm.backgroundImage + ")") })})])])])}
var staticRenderFns = []

export { render, staticRenderFns }